<template>
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-overlay"></div>
    <div class="content-body">
      <!-- Stats Vertical Card -->
      <div class="row">
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myClientTickets }}</h2>
              <p class="card-text mb-0">Tickets dos meus clientes</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myTickets }}</h2>
              <p class="card-text mb-0">Tickets na minha pauta</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ myTicketsToday }}</h2>
              <p class="card-text mb-0">Minha pauta para hoje</p>
              <small>(Em período de entrega de fase)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsTotal }}</h2>
              <p class="card-text mb-0">Tickets do meu setor</p>
              <small>(Aguardando e Abertos)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsTodayTotal }}</h2>
              <p class="card-text mb-0">Tickets do setor para hoje</p>
              <small>(Em período de entrega de fase)</small>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-6">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="font-weight-bolder">{{ mySectorTicketsLateTotal }}</h2>
              <p class="card-text mb-0">Tickets do setor atrasados</p>
              <small>(Passou do período de entrega de fase)</small>
            </div>
          </div>
        </div>
      </div>

      <!--/ Stats Vertical Card -->
      <!--<div class="row">
        TODOS OS TICKETS
        <div class="col-lg-12 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Status dos Tickets | Por mês</h4>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ AllTicketsSearch }}
                </button>
                <div class="dropdown-menu">
                  <p class="dropdown-item m-0" v-for="item in teamSelect" :key="item.status" v-on:click="calculaGraphAllTickets(item)">{{ item.status }}</p>
                </div>
              </div>
            </div>
            <AllTicketsGraph
              :series="seriesAllTickets"
            ></AllTicketsGraph>
          </div>
        </div>
        TODOS OS TICKETS
      </div>
      <div class="row">
      </div>-->

      <div class="row">
        <div class="col-xl-12 col-md-12 col-12">
          <div class="card card-statistics">
            <div class="card-header pb-0">
              <h4 class="card-title">Estatísticas por Equipe:</h4>
              <div class="d-flex align-items-center"></div>
            </div>
            <div class="card-body statistics-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="font-weight-bolder">&nbsp;</th>
                      <th class="font-weight-bolder" v-for="team in Teams">
                        <a :href="'/#/tickets/' + teamProc[team.value]">{{ team.status }}</a>
                      </th>
                      <th class="font-weight-bolder">Totais</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Qtde de clientes</td>
                      <td class="text-nowrap" v-for="(value, key) in clientesPorEquipe">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              clientesTotal !== 0
                                ? ((value * 100) / clientesTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "-")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{clientesTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Tickets</td>
                      <td class="text-nowrap" v-for="(value, key) in abertosPorEquipe">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              abertosTotal !== 0
                                ? ((value * 100) / abertosTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "-")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{abertosTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Com cliente</td>
                      <td class="text-nowrap" v-for="(value, key) in comClientePorEquipe">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              comClienteTotal !== 0
                                ? ((value * 100) / comClienteTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{comClienteTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Arte Final</td>
                      <td class="text-nowrap" v-for="(value, key) in artefinalEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              artefinalTotal !== 0
                                ? ((value * 100) / artefinalTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{artefinalTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Atendimento</td>
                      <td class="text-nowrap" v-for="(value, key) in atendimentoEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              atendimentoTotal !== 0
                                ? ((value * 100) / atendimentoTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{atendimentoTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Direção de Arte</td>
                      <td class="text-nowrap" v-for="(value, key) in direcaodearteEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              direcaodearteTotal !== 0
                                ? ((value * 100) / direcaodearteTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{direcaodearteTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Orçamento</td>
                      <td class="text-nowrap" v-for="(value, key) in orcamentoEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              orcamentoTotal !== 0
                                ? ((value * 100) / orcamentoTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{orcamentoTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Produção Multimídia</td>
                      <td class="text-nowrap" v-for="(value, key) in pmaEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              pmaTotal !== 0
                                ? ((value * 100) / pmaTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{pmaTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Redação</td>
                      <td class="text-nowrap" v-for="(value, key) in redacaoEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              redacaoTotal !== 0
                                ? ((value * 100) / redacaoTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{redacaoTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Revisão</td>
                      <td class="text-nowrap" v-for="(value, key) in revisaoEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              revisaoTotal !== 0
                                ? ((value * 100) / revisaoTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{revisaoTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Social Media</td>
                      <td class="text-nowrap" v-for="(value, key) in socialMediaEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{ value }}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              socialMediaTotal !== 0
                                ? ((value * 100) / socialMediaTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{socialMediaTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Web</td>
                      <td class="text-nowrap" v-for="(value, key) in webEquipes">
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{value}}</span>
                          <span class="font-small-2 text-muted">&nbsp;
                            {{
                              webTotal !== 0
                                ? ((value * 100) / webTotal).toFixed(1) + "%"
                                : (value !== 0 ? "0%" : "0%")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <span class="font-weight-bolder mb-25">{{webTotal}}</span>
                          <span class="font-small-2 text-muted">&nbsp; (100%)</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-lg-12 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Ranking de Serviços | Estatísticas por Equipe</h4>
              <div class="btn-group">
              </div>
            </div>
            <MixChart/>
          </div>
        </div>
        TICKETS PARA HOJE
        <div class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Tickets para hoje</h4>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ TodaySearch }}
                </button>
                <div class="dropdown-menu">
                  <p class="dropdown-item m-0" v-for="item in timeSelect" :key="item.status" v-on:click="calculaGraphToday(item)">{{ item.status }}</p>
                </div>
              </div>
            </div>
            <TodayGraph
              :series="seriesToday"
              :info1="todayInfo1"
              :info2="todayInfo2"
              :info3="todayInfo3"
            ></TodayGraph>
          </div>
        </div>
        TICKETS PARA HOJE
      </div>-->

      <!--<div class="row">
        <div class="col-lg-12 col-12">
          <ExpectedChart/>
        </div>
      </div>-->

      <!--<div class="row">
        TICKETS POR EQUIPE
        <div class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Tickets por Equipe</h4>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ ByTeamSearch }}
                </button>
                <div class="dropdown-menu">
                  <p class="dropdown-item m-0" v-for="item in timeSelect" :key="item.status" v-on:click="calculaGraphByTeam(item)">{{ item.status }}</p>
                </div>
              </div>
            </div>
            <TeamDonutGraph
              :series="seriesByTeam"
            ></TeamDonutGraph>
          </div>
        </div>-->
        <!-- TICKETS POR EQUIPE -->

        <!-- REPROVADOS POR EQUIPE -->
         <!--<div class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Tickets Aprovados</h4>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ ReprovedsSearch }}
                </button>
                <div class="dropdown-menu">
                  <p class="dropdown-item m-0" v-for="item in periodSelect" :key="item.status" v-on:click="calculaGraphReproveds(item)">{{ item.status }}</p>
                </div>
              </div>
            </div>
            <MultipleRadialGraph
              :series="seriesReproveds"
            ></MultipleRadialGraph>
          </div>
        </div>-->
         <!--<div class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between pb-0">
              <h4 class="card-title mb-1">Tentativas de Aprovação</h4>
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ ReprovesSearch }}
                </button>
                <div class="dropdown-menu">
                  <p class="dropdown-item m-0" v-for="item in periodSelect" :key="item.status" v-on:click="calculaGraphReproves(item)">{{ item.status }}</p>
                </div>
              </div>
            </div>
            <MultipleRadialGraph
              :series="seriesReproves"
            ></MultipleRadialGraph>
          </div>
        </div>
      REPROVADOS POR EQUIPE 
      </div>-->
    </div>
  </div>
</template>
<script>
import RadialGraph from '../components/RadialGraph'
import TodayGraph from '../components/TodayGraph'
import AllTicketsGraph from '../components/AllTicketsGraph'
import TeamDonutGraph from '../components/TeamDonutGraph'
import MultipleRadialGraph from '../components/MultipleRadialGraph'
import MixChart from '../components/MixChart'
import ExpectedChart from '../components/ExpectedChart'
import VueApexCharts from 'vue-apexcharts' 
import { H } from 'hennig-common'
import {
  AlertTriangleIcon,
  FrownIcon,
  ActivityIcon,
  EyeIcon,
  EditIcon,
  MonitorIcon,
  HeadphonesIcon,
  ImageIcon,
  UserCheckIcon,
} from 'vue-feather-icons'

export default {
  name: 'Dashboard',
  components: {
    AlertTriangleIcon,
    FrownIcon,
    ActivityIcon,
    EyeIcon,
    EditIcon,
    MonitorIcon,
    HeadphonesIcon,
    ImageIcon,
    UserCheckIcon,
    VueApexCharts,
    RadialGraph,
    TodayGraph,
    AllTicketsGraph,
    TeamDonutGraph,
    MultipleRadialGraph,
    MixChart,
    ExpectedChart,
  },
  data () {
    return {
      userName: '',
      teamProc: [],
      allTickets: 0,
      lateTickets: 0,
      todayTickets: 0,
      lateTicketsClube: 0,
      todayTicketsClube: 0,
      revisaoEquipes: 0,
      revisaoTotal: 0,
      redacaoEquipes: 0,
      redacaoTotal: 0,
      direcaodearteEquipes: 0,
      artefinalEquipes: 0,
      direcaodearteTotal: 0,
      artefinalTotal: 0,
      atendimentoEquipes: 0,
      atendimentoTotal: 0,
      socialMediaEquipes: 0,
      socialMediaTotal: 0,
      webEquipes: 0,
      webTotal: 0,
      clienteEquipes: 0,
      clienteTotal: 0,
      midiaEquipes: 0,
      midiaTotal: 0,
      pmaEquipes: 0,
      pmaTotal: 0,
      orcamentoEquipes: 0,
      orcamentoTotal: 0,
      outrosEquipes: 0,
      outrosTotal: 0,
      abertosPorEquipe: 0,
      abertosTotal: 0,
      clientesPorEquipe: 0,
      clientesTotal: 0,
      comClientePorEquipe: 0,
      abertosClube: 0,
      comClienteTotal: 0,

      /*horasPorEquipe: 0,

      seriesClube: [0],
      seriesUser: [0],
      seriesCustomer: [0],

      timeSelect: [
        { status: 'Todos', value: 'all' },
        { status: 'Atrasados', value: 'late' },
        { status: 'No Prazo', value: 'active' },
        { status: 'Adiantados', value: 'ahead' },
      ],
      periodSelect: [
        { status: '10 Dias', value: 'dezDias' },
        { status: '30 Dias', value: 'trintaDias' },
        { status: '60 Dias', value: 'sessentaDias' },
      ],
      teamSelect: [
        { status: 'Todas', value: 'Todas' },
        { status: 'Equipe Mário', value: 'Equipe Mário' },
        { status: 'Equipe Luigi', value: 'Equipe Luigi' },
        { status: 'Equipe Yoshi', value: 'Equipe Yoshi' },
        { status: 'Equipe Toad', value: 'Equipe Toad' },
        { status: 'Equipe Goomba', value: 'Equipe Goomba' },
        { status: 'Equipe Bowser', value: 'Equipe Bowser' },
      ],*/

      Teams: [ //valores defautl de Teams
        { status: 'Equipe Mário', value: 'Equipe Mário' },
        { status: 'Equipe Luigi', value: 'Equipe Luigi' },
        { status: 'Equipe Yoshi', value: 'Equipe Yoshi' },
        { status: 'Equipe Toad', value: 'Equipe Toad' },
        { status: 'Equipe Goomba', value: 'Equipe Goomba' },
        { status: 'Equipe Bowser', value: 'Equipe Bowser' },
      ],

     /* TodaySearch: 'Todos',
      TodayTickets: 0,
      TodayTicketsLate: 0,
      TodayTicketsAll: 0,
      TodayTicketsUpdated: 0,
      TodayTicketsAhead: 0,
      seriesToday: [0],
      todayInfo1: 0,
      todayInfo2: 0,
      todayInfo3: 0,

      AllTicketsSearch: 'Todos',
      AllTicketsMonthly: [],
      AllTicketsMonthlyClosed: [],
      AllTicketsMonthlyWaiting: [],
      AllTicketsMonthlyCancel: [],
      seriesAllTickets: [{
        name: 'Abertos',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: '#66DA26'
      }, {
        name: 'Fechados',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: '#7367f0'
      }, {
        name: 'Cancelados',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: '#E91E63'
      }, {
        name: 'Aguardando',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: '#ff9f43'
      }],
      janOpen: 0,
      fevOpen: 0,
      marOpen: 0,
      abrOpen: 0,
      maiOpen: 0,
      junOpen: 0,
      julOpen: 0,
      agoOpen: 0,
      setOpen: 0,
      outOpen: 0,
      novOpen: 0,
      dezOpen: 0,

      janClosed: 0,
      fevClosed: 0,
      marClosed: 0,
      abrClosed: 0,
      maiClosed: 0,
      junClosed: 0,
      julClosed: 0,
      agoClosed: 0,
      setClosed: 0,
      outClosed: 0,
      novClosed: 0,
      dezClosed: 0,

      janWaiting: 0,
      fevWaiting: 0,
      marWaiting: 0,
      abrWaiting: 0,
      maiWaiting: 0,
      junWaiting: 0,
      julWaiting: 0,
      agoWaiting: 0,
      setWaiting: 0,
      outWaiting: 0,
      novWaiting: 0,
      dezWaiting: 0,

      ByTeamSearch: 'Todos',
      ByTeamDataLate: [],
      ByTeamDataActive: [],
      ByTeamDataAhead: [],
      seriesByTeam: [0, 0, 0, 0, 0, 0],

      seriesReproveds: [10, 10, 10, 10, 10, 10],
      ReprovedsByTeam60: [],
      ReprovedsByTeam30: [],
      ReprovedsByTeam10: [],
      ReprovedsSearch: '10 Dias',

      seriesReproves: [10, 10, 10, 10, 10, 10],
      ReprovedByTeam60: [],
      ReprovedByTeam30: [],
      ReprovedByTeam10: [],
      ReprovesSearch: '10 Dias',*/

      myTickets: 0,
      myTicketsToday: 0,
      myTicketsLate: 0,
      myClientTickets: 0,
      mySectorTickets: 0,
      mySectorTicketsTotal: 0,
      mySectorTicketsLate: 0,
      mySectorTicketsLateTotal: 0,
      mySectorTicketsToday: 0,
      mySectorTicketsTodayTotal: 0, 
    }
  },
  /*watch: {
    'abertosTotal' (new_value) {
      if (new_value) {
        this.calculaGraphClube()
      }
    },
    'myTickets' (new_value) {
      if (new_value) {
        this.calculaGraphUser()
      }
    },

    'clientesTotal' (new_value) {
      if (new_value) {
        this.calculaGraphCustomer()
      }
    },
  },*/

  mounted () {
    let self = this
    H.rpc('Dashboard', 'HeaderData', [], (r) => {
      if (r) {
        this.myTickets = r.myTickets
        this.myTicketsToday = r.myTicketsToday
        this.myTicketsLate = r.myTicketsLate
        this.myClientTickets = r.myClientTickets
        this.mySectorTickets = r.mySectorTickets
        this.mySectorTicketsLate = r.mySectorTicketsLate
        this.mySectorTicketsToday = r.mySectorTicketsToday

        this.mySectorTicketsTotal = this.mySectorTickets.reduce((mySectorTicketsTotal, mySectorTickets) => {
          return mySectorTickets + mySectorTicketsTotal
        }, 0)

        this.mySectorTicketsLateTotal = this.mySectorTicketsLate.reduce((mySectorTicketsLateTotal, mySectorTicketsLate) => {
          return mySectorTicketsLate + mySectorTicketsLateTotal
        }, 0)

        this.mySectorTicketsTodayTotal = this.mySectorTicketsToday.reduce((mySectorTicketsTodayTotal, mySectorTicketsToday) => {
          return mySectorTicketsToday + mySectorTicketsTodayTotal
        }, 0)
      }
    })

    H.rpc('Dashboard', 'TodayData', [], (r) => {
      if (r) {
        this.TodayTickets = r.TodayTickets
        this.TodayTicketsLate = r.TodayTicketsLate
        this.TodayTicketsUpdated = r.TodayTicketsUpdated
        this.TodayTicketsAhead = r.TodayTicketsAhead

        this.todayInfo1 = r.TodayTicketsLate + r.TodayTickets + r.TodayTicketsUpdated,
        this.todayInfo2 = r.TodayTicketsUpdated,
        this.todayInfo3 = r.TodayTickets + r.TodayTicketsLate,

        this.seriesToday = [
          (r.TodayTicketsUpdated * 100 / (r.TodayTicketsUpdated + r.TodayTickets + r.TodayTicketsLate)).toFixed(1),
        ]
      }
    })
    
    /*H.rpc('Dashboard', 'AllTicketsData', [], (r) => {
      if (r) {
        this.AllTicketsMonthly = r.AllTicketsMonthly
        this.AllTicketsMonthlyClosed = r.AllTicketsMonthlyClosed
        this.AllTicketsMonthlyWaiting = r.AllTicketsMonthlyWaiting
        this.AllTicketsMonthlyCancel = r.AllTicketsMonthlyCancel

        for (const [key, value] of Object.entries(r.AllTicketsMonthly)) { //Aqui ta sendo feito o loop por todos os tickets abertos do mes
          if (key !== '# Cliente Inativo') { //estamos filtrando para não listar o array de clientes inativos;
            this.janOpen += value.Jan;
            this.fevOpen += value.Fev;
            this.marOpen += value.Mar;
            this.abrOpen += value.Abr;
            this.maiOpen += value.Mai;
            this.junOpen += value.Jun;
            this.julOpen += value.Jul;
            this.agoOpen += value.Ago;
            this.setOpen += value.Set;
            this.outOpen += value.Out;
            this.novOpen += value.Nov;
            this.dezOpen += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyClosed)) { //Aqui ta sendo feito o loop por todos os tickets fechados do mes
          if (key !== '# Cliente Inativo') { //estamos filtrando para não listar o array de clientes inativos;
            this.janClosed += value.Jan;
            this.fevClosed += value.Fev;
            this.marClosed += value.Mar;
            this.abrClosed += value.Abr;
            this.maiClosed += value.Mai;
            this.junClosed += value.Jun;
            this.julClosed += value.Jul;
            this.agoClosed += value.Ago;
            this.setClosed += value.Set;
            this.outClosed += value.Out;
            this.novClosed += value.Nov;
            this.dezClosed += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyWaiting)) { //Aqui ta sendo feito o loop por todos os tickets aguardando do mes
          if (key !== '# Cliente Inativo') { //estamos filtrando para não listar o array de clientes inativos;
            this.janWaiting += value.Jan;
            this.fevWaiting += value.Fev;
            this.marWaiting += value.Mar;
            this.abrWaiting += value.Abr;
            this.maiWaiting += value.Mai;
            this.junWaiting += value.Jun;
            this.julWaiting += value.Jul;
            this.agoWaiting += value.Ago;
            this.setWaiting += value.Set;
            this.outWaiting += value.Out;
            this.novWaiting += value.Nov;
            this.dezWaiting += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyCancel)) { //Aqui ta sendo feito o loop por todos os tickets cancelados do mes
          if (key !== '# Cliente Inativo') { //estamos filtrando para não listar o array de clientes inativos;
            this.janCancel += value.Jan;
            this.fevCancel += value.Fev;
            this.marCancel += value.Mar;
            this.abrCancel += value.Abr;
            this.maiCancel += value.Mai;
            this.junCancel += value.Jun;
            this.julCancel += value.Jul;
            this.agoCancel += value.Ago;
            this.setCancel += value.Set;
            this.outCancel += value.Out;
            this.novCancel += value.Nov;
            this.dezCancel += value.Dez;
          }
        }
        // isso aqui eh algum grafico q foi removido
        this.seriesAllTickets = [{
          name: 'Abertos',
          data: [
            this.janOpen,
            this.fevOpen,
            this.marOpen,
            this.abrOpen,
            this.maiOpen,
            this.junOpen,
            this.julOpen,
            this.agoOpen,
            this.setOpen,
            this.outOpen,
            this.novOpen,
            this.dezOpen
          ],
          color: '#66DA26'
        }, {
          name: 'Fechados',
          data: [
            this.janClosed,
            this.fevClosed,
            this.marClosed,
            this.abrClosed,
            this.maiClosed,
            this.junClosed,
            this.julClosed,
            this.agoClosed,
            this.setClosed,
            this.outClosed,
            this.novClosed,
            this.dezClosed
          ],
          color: '#7367f0'
        }, {
          name: 'Aguardando',
          data: [
            this.janWaiting,
            this.fevWaiting,
            this.marWaiting,
            this.abrWaiting,
            this.maiWaiting,
            this.junWaiting,
            this.julWaiting,
            this.agoWaiting,
            this.setWaiting,
            this.outWaiting,
            this.novWaiting,
            this.dezWaiting
          ],
          color: '#ff9f43'
        }, {
          name: 'Cancelado',
          data: [
            this.janCancel,
            this.fevCancel,
            this.marCancel,
            this.abrCancel,
            this.maiCancel,
            this.junCancel,
            this.julCancel,
            this.agoCancel,
            this.setCancel,
            this.outCancel,
            this.novCancel,
            this.dezCancel
          ],
          color: '#E91E63'
        }]
      }
    })*/

    H.rpc('Dashboard', 'myTicketsData', [], (r) => {
      if (r) {
        this.userName = r.userName
        this.teamProc = r.teamProc
        this.allTickets = r.all || 0;
        this.lateTickets = r.late || 0;
        this.todayTickets = r.today || 0;
        this.lateTicketsClube = r.lateClube
        this.todayTicketsClube = r.todayClube
        this.revisaoEquipes = r.revisaoEquipes
        this.redacaoEquipes = r.redacaoEquipes
        this.direcaodearteEquipes = r.direcaodearteEquipes
        this.artefinalEquipes = r.artefinalEquipes
        this.atendimentoEquipes = r.atendimentoEquipes
        this.socialMediaEquipes = r.socialMediaEquipes
        this.webEquipes = r.webEquipes
        this.clienteEquipes = r.clienteEquipes
        this.midiaEquipes = r.midiaEquipes
        this.pmaEquipes = r.pmaEquipes
        this.orcamentoEquipes = r.orcamentoEquipes
        this.outrosEquipes = r.outrosEquipes
        this.abertosPorEquipe = r.abertosPorEquipe
        this.clientesPorEquipe = r.clientesPorEquipe
        this.comClientePorEquipe = r.comClientePorEquipe

        this.Teams = []; // Zerando os valores padrão do array Teams

        for (const [key, value] of Object.entries(r.teamProc)) { // Substituindo os valores status e value de Teams
            this.Teams.push({status: key, value: value})
        } 

        /* Somando todos os tickets por setor */
        
        for (const [key, value] of Object.entries(r.atendimentoEquipes)) {
          this.atendimentoTotal += value;  
        }

        for (const [key, value] of Object.entries(r.redacaoEquipes)) {
            this.redacaoTotal += value;
        }

        for (const [key, value] of Object.entries(r.direcaodearteEquipes)) {
            this.direcaodearteTotal += value;
        }

        for (const [key, value] of Object.entries(r.artefinalEquipes)) {
            this.artefinalTotal += typeof value === "undefined" ? 0 : value;
        }

        for (const [key, value] of Object.entries(r.revisaoEquipes)) {
            this.revisaoTotal += typeof value === "undefined" ? 0 : value;
        }

        for (const [key, value] of Object.entries(r.socialMediaEquipes)) {
            this.socialMediaTotal += value;
        }

        for (const [key, value] of Object.entries(r.webEquipes)) {
            this.webTotal += value;
        }

        for (const [key, value] of Object.entries(r.clienteEquipes)) {
            this.clienteTotal += value;
        }

        for (const [key, value] of Object.entries(r.midiaEquipes)) {
            this.midiaTotal += value;
        }

        for (const [key, value] of Object.entries(r.pmaEquipes)) {
            this.pmaTotal += value;
        }

        for (const [key, value] of Object.entries(r.orcamentoEquipes)) {
            this.orcamentoTotal += value;
        }

        for (const [key, value] of Object.entries(r.outrosEquipes)) {
            this.outrosTotal += value;
        }

        for (const [key, value] of Object.entries(r.abertosPorEquipe)) {
          this.abertosTotal += value;
        }

        for (const [key, value] of Object.entries(r.clientesPorEquipe)) {
          if (value > 0) { // Verifica se o cliente possui tickets abertos ou aguardando (value > 0)
            this.clientesTotal += value;
          }
        }

        for (const [key, value] of Object.entries(r.comClientePorEquipe)) {
            this.comClienteTotal += value;
        }

        /* Pegando todos os tickets por equipe */

        for (const [team, tickets] of Object.entries(this.atendimentoEquipes)) {
            this.atendimentoEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.redacaoEquipes)) {
            this.redacaoEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.direcaodearteEquipes)) {
            this.direcaodearteEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.artefinalEquipes)) {
            this.artefinalEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.revisaoEquipes)) {
            this.revisaoEquipes[team] = tickets;        
        }

        for (const [team, tickets] of Object.entries(this.socialMediaEquipes)) {
            this.socialMediaEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.webEquipes)) {
            this.webEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.clienteEquipes)) {
            this.clienteEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.midiaEquipes)) {
            this.midiaEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.pmaEquipes)) {
            this.pmaEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.orcamentoEquipes)) {
            this.orcamentoEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.outrosEquipes)) {
            this.outrosEquipes[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.abertosPorEquipe)) {
            this.abertosPorEquipe[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.clientesPorEquipe)) {
            this.clientesPorEquipe[team] = tickets;
        }

        for (const [team, tickets] of Object.entries(this.comClientePorEquipe)) {
            this.comClientePorEquipe[team] = tickets;
        }
      }
    })

    /*H.rpc('Dashboard', 'ByTeamData', [], (r) => {
      if (r) {
        this.ByTeamDataLate = r.ByTeamDataLate
        this.ByTeamDataActive = r.ByTeamDataActive
        this.ByTeamDataAhead = r.ByTeamDataAhead

        this.seriesByTeam = Object.keys(r.ByTeamDataLate).map(team => {
          const lateValue = r.ByTeamDataLate[team] || 0;
          const activeValue = r.ByTeamDataActive[team] || 0;
          return lateValue + activeValue;
        });
      }
    })

    H.rpc('Dashboard', 'ReprovedByTeam', [], (r) => {
      if (r) {
        this.ReprovedByTeam60 = r.ReprovedByTeam60
        this.ReprovedByTeam30 = r.ReprovedByTeam30
        this.ReprovedByTeam10 = r.ReprovedByTeam10

        this.ReprovedsByTeam60 = r.ReprovedsByTeam60
        this.ReprovedsByTeam30 = r.ReprovedsByTeam30
        this.ReprovedsByTeam10 = r.ReprovedsByTeam10

        this.seriesReproves = Object.keys(r.ReprovedByTeam10).map(team => {
          return typeof r.ReprovedByTeam10[team] === "undefined" ? 0 : r.ReprovedByTeam10[team];
        });

        this.seriesReproveds = Object.keys(r.ReprovedsByTeam10).map(team => {
          return typeof r.ReprovedsByTeam10[team] === "undefined" ? 0 : r.ReprovedsByTeam10[team];
        });
      }
    })
  },
  methods: {*/
    /*calculaGraphToday (param) {
      this.TodaySearch = param.status

      if(param.value == 'all'){
        this.todayInfo1 = this.TodayTickets + this.TodayTicketsUpdated + this.TodayTicketsLate
        this.todayInfo2 = this.TodayTicketsUpdated
        this.todayInfo3 = this.TodayTicketsLate + this.TodayTickets

        this.seriesToday = [
          ((this.todayInfo2 * 100) / this.todayInfo1).toFixed(1),
        ]
      }
      if(param.value == 'late'){
        this.todayInfo1 = this.TodayTicketsLate
        this.todayInfo2 = 0
        this.todayInfo3 = this.TodayTicketsLate

        this.seriesToday = [
          ((this.todayInfo2 * 100) / this.todayInfo3).toFixed(1),
        ]
      }
      if(param.value == 'active'){
        this.todayInfo1 = this.TodayTickets + this.TodayTicketsUpdated
        this.todayInfo2 = this.TodayTicketsUpdated
        this.todayInfo3 = this.TodayTickets

        this.seriesToday = [
          ((this.todayInfo2 * 100) / this.todayInfo1).toFixed(1),
        ]
      }
      if(param.value == 'ahead'){
        this.todayInfo1 = 0
        this.todayInfo2 = this.TodayTicketsAhead
        this.todayInfo3 = 0

        if(todayInfo2 > 0){
          this.seriesToday = [
            ((this.todayInfo2 * 100) / this.todayInfo2).toFixed(1),
          ]
        } else {
          this.seriesToday = [
            0,
          ]
        }
      }
    },
    calculaGraphAllTickets (param){
      this.AllTicketsSearch = param.status

      this.janOpen = 0
      this.fevOpen = 0
      this.marOpen = 0
      this.abrOpen = 0
      this.maiOpen = 0
      this.junOpen = 0
      this.julOpen = 0
      this.agoOpen = 0
      this.setOpen = 0
      this.outOpen = 0
      this.novOpen = 0
      this.dezOpen = 0

      this.janClosed = 0
      this.fevClosed = 0
      this.marClosed = 0
      this.abrClosed = 0
      this.maiClosed = 0
      this.junClosed = 0
      this.julClosed = 0
      this.agoClosed = 0
      this.setClosed = 0
      this.outClosed = 0
      this.novClosed = 0
      this.dezClosed = 0

      this.janWaiting = 0
      this.fevWaiting = 0
      this.marWaiting = 0
      this.abrWaiting = 0
      this.maiWaiting = 0
      this.junWaiting = 0
      this.julWaiting = 0
      this.agoWaiting = 0
      this.setWaiting = 0
      this.outWaiting = 0
      this.novWaiting = 0
      this.dezWaiting = 0

      this.janCancel = 0
      this.fevCancel = 0
      this.marCancel = 0
      this.abrCancel = 0
      this.maiCancel = 0
      this.junCancel = 0
      this.julCancel = 0
      this.agoCancel = 0
      this.setCancel = 0
      this.outCancel = 0
      this.novCancel = 0
      this.dezCancel = 0

      if(param.value != 'Todas'){
        this.janOpen = this.AllTicketsMonthly[param.value].Jan
        this.fevOpen = this.AllTicketsMonthly[param.value].Fev
        this.marOpen = this.AllTicketsMonthly[param.value].Mar
        this.abrOpen = this.AllTicketsMonthly[param.value].Abr
        this.maiOpen = this.AllTicketsMonthly[param.value].Mai
        this.junOpen = this.AllTicketsMonthly[param.value].Jun
        this.julOpen = this.AllTicketsMonthly[param.value].Jul
        this.agoOpen = this.AllTicketsMonthly[param.value].Ago
        this.setOpen = this.AllTicketsMonthly[param.value].Set
        this.outOpen = this.AllTicketsMonthly[param.value].Out
        this.novOpen = this.AllTicketsMonthly[param.value].Nov
        this.dezOpen = this.AllTicketsMonthly[param.value].Dez

        this.janClosed = this.AllTicketsMonthlyClosed[param.value].Jan
        this.fevClosed = this.AllTicketsMonthlyClosed[param.value].Fev
        this.marClosed = this.AllTicketsMonthlyClosed[param.value].Mar
        this.abrClosed = this.AllTicketsMonthlyClosed[param.value].Abr
        this.maiClosed = this.AllTicketsMonthlyClosed[param.value].Mai
        this.junClosed = this.AllTicketsMonthlyClosed[param.value].Jun
        this.julClosed = this.AllTicketsMonthlyClosed[param.value].Jul
        this.agoClosed = this.AllTicketsMonthlyClosed[param.value].Ago
        this.setClosed = this.AllTicketsMonthlyClosed[param.value].Set
        this.outClosed = this.AllTicketsMonthlyClosed[param.value].Out
        this.novClosed = this.AllTicketsMonthlyClosed[param.value].Nov
        this.dezClosed = this.AllTicketsMonthlyClosed[param.value].Dez

        this.janWaiting = this.AllTicketsMonthlyWaiting[param.value].Jan
        this.fevWaiting = this.AllTicketsMonthlyWaiting[param.value].Fev
        this.marWaiting = this.AllTicketsMonthlyWaiting[param.value].Mar
        this.abrWaiting = this.AllTicketsMonthlyWaiting[param.value].Abr
        this.maiWaiting = this.AllTicketsMonthlyWaiting[param.value].Mai
        this.junWaiting = this.AllTicketsMonthlyWaiting[param.value].Jun
        this.julWaiting = this.AllTicketsMonthlyWaiting[param.value].Jul
        this.agoWaiting = this.AllTicketsMonthlyWaiting[param.value].Ago
        this.setWaiting = this.AllTicketsMonthlyWaiting[param.value].Set
        this.outWaiting = this.AllTicketsMonthlyWaiting[param.value].Out
        this.novWaiting = this.AllTicketsMonthlyWaiting[param.value].Nov
        this.dezWaiting = this.AllTicketsMonthlyWaiting[param.value].Dez

        this.janCancel = this.AllTicketsMonthlyCancel[param.value].Jan
        this.fevCancel = this.AllTicketsMonthlyCancel[param.value].Fev
        this.marCancel = this.AllTicketsMonthlyCancel[param.value].Mar
        this.abrCancel = this.AllTicketsMonthlyCancel[param.value].Abr
        this.maiCancel = this.AllTicketsMonthlyCancel[param.value].Mai
        this.junCancel = this.AllTicketsMonthlyCancel[param.value].Jun
        this.julCancel = this.AllTicketsMonthlyCancel[param.value].Jul
        this.agoCancel = this.AllTicketsMonthlyCancel[param.value].Ago
        this.setCancel = this.AllTicketsMonthlyCancel[param.value].Set
        this.outCancel = this.AllTicketsMonthlyCancel[param.value].Out
        this.novCancel = this.AllTicketsMonthlyCancel[param.value].Nov
        this.dezCancel = this.AllTicketsMonthlyCancel[param.value].Dez

        this.seriesAllTickets = [{
          name: 'Abertos',
          data: [
            this.janOpen,
            this.fevOpen,
            this.marOpen,
            this.abrOpen,
            this.maiOpen,
            this.junOpen,
            this.julOpen,
            this.agoOpen,
            this.setOpen,
            this.outOpen,
            this.novOpen,
            this.dezOpen
          ],
          color: '#66DA26'
        }, {
          name: 'Fechados',
          data: [
            this.janClosed,
            this.fevClosed,
            this.marClosed,
            this.abrClosed,
            this.maiClosed,
            this.junClosed,
            this.julClosed,
            this.agoClosed,
            this.setClosed,
            this.outClosed,
            this.novClosed,
            this.dezClosed
          ],
          color: '#7367f0'
        }, {
          name: 'Aguardando',
          data: [
            this.janWaiting,
            this.fevWaiting,
            this.marWaiting,
            this.abrWaiting,
            this.maiWaiting,
            this.junWaiting,
            this.julWaiting,
            this.agoWaiting,
            this.setWaiting,
            this.outWaiting,
            this.novWaiting,
            this.dezWaiting
          ],
          color: '#ff9f43'
        }, {
          name: 'Cancelados',
          data: [
            this.janCancel,
            this.fevCancel,
            this.marCancel,
            this.abrCancel,
            this.maiCancel,
            this.junCancel,
            this.julCancel,
            this.agoCancel,
            this.setCancel,
            this.outCancel,
            this.novCancel,
            this.dezCancel
          ],
          color: '#E91E63'
        }]
      } else {
        for (const [key, value] of Object.entries(r.AllTicketsMonthly)) {
          if (key !== '# Cliente Inativo') {
            this.janOpen += value.Jan;
            this.fevOpen += value.Fev;
            this.marOpen += value.Mar;
            this.abrOpen += value.Abr;
            this.maiOpen += value.Mai;
            this.junOpen += value.Jun;
            this.julOpen += value.Jul;
            this.agoOpen += value.Ago;
            this.setOpen += value.Set;
            this.outOpen += value.Out;
            this.novOpen += value.Nov;
            this.dezOpen += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyClosed)) {
          if (key !== '# Cliente Inativo') {
            this.janClosed += value.Jan;
            this.fevClosed += value.Fev;
            this.marClosed += value.Mar;
            this.abrClosed += value.Abr;
            this.maiClosed += value.Mai;
            this.junClosed += value.Jun;
            this.julClosed += value.Jul;
            this.agoClosed += value.Ago;
            this.setClosed += value.Set;
            this.outClosed += value.Out;
            this.novClosed += value.Nov;
            this.dezClosed += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyWaiting)) {
          if (key !== '# Cliente Inativo') {
            this.janWaiting += value.Jan;
            this.fevWaiting += value.Fev;
            this.marWaiting += value.Mar;
            this.abrWaiting += value.Abr;
            this.maiWaiting += value.Mai;
            this.junWaiting += value.Jun;
            this.julWaiting += value.Jul;
            this.agoWaiting += value.Ago;
            this.setWaiting += value.Set;
            this.outWaiting += value.Out;
            this.novWaiting += value.Nov;
            this.dezWaiting += value.Dez;
          }
        }

        for (const [key, value] of Object.entries(r.AllTicketsMonthlyCancel)) {
          if (key !== '# Cliente Inativo') {
            this.janCancel += value.Jan;
            this.fevCancel += value.Fev;
            this.marCancel += value.Mar;
            this.abrCancel += value.Abr;
            this.maiCancel += value.Mai;
            this.junCancel += value.Jun;
            this.julCancel += value.Jul;
            this.agoCancel += value.Ago;
            this.setCancel += value.Set;
            this.outCancel += value.Out;
            this.novCancel += value.Nov;
            this.dezCancel += value.Dez;
          }
        }

        this.seriesAllTickets = [{
          name: 'Abertos',
          data: [
            this.janOpen,
            this.fevOpen,
            this.marOpen,
            this.abrOpen,
            this.maiOpen,
            this.junOpen,
            this.julOpen,
            this.agoOpen,
            this.setOpen,
            this.outOpen,
            this.novOpen,
            this.dezOpen
          ],
          color: '#66DA26'
        }, {
          name: 'Fechados',
          data: [
            this.janClosed,
            this.fevClosed,
            this.marClosed,
            this.abrClosed,
            this.maiClosed,
            this.junClosed,
            this.julClosed,
            this.agoClosed,
            this.setClosed,
            this.outClosed,
            this.novClosed,
            this.dezClosed
          ],
          color: '#7367f0'
        }, {
          name: 'Aguardando',
          data: [
            this.janWaiting,
            this.fevWaiting,
            this.marWaiting,
            this.abrWaiting,
            this.maiWaiting,
            this.junWaiting,
            this.julWaiting,
            this.agoWaiting,
            this.setWaiting,
            this.outWaiting,
            this.novWaiting,
            this.dezWaiting
          ],
          color: '#ff9f43'
        }, {
          name: 'Cancelados',
          data: [
            this.janCancel,
            this.fevCancel,
            this.marCancel,
            this.abrCancel,
            this.maiCancel,
            this.junCancel,
            this.julCancel,
            this.agoCancel,
            this.setCancel,
            this.outCancel,
            this.novCancel,
            this.dezCancel
          ],
          color: '#E91E63'
        }]
      }
    },

    /*calculaGraphClube() {
      if (this.abertosTotal !== 0) {
        const percentage = (this.lateTicketsClube * 100) / this.abertosTotal;
        this.seriesClube = [percentage.toFixed(1)];
      } else {
        this.seriesClube = [0]; // ou qualquer outro valor adequado para representar a porcentagem zero
      }
    },*/

    /*calculaGraphCustomer() {
      this.seriesCustomer = Object.keys(this.teamProc).map((key) => {
        return typeof this.clientesPorEquipe[key] === "undefined" ? 0 : this.clientesPorEquipe[key];
      });
    },

    /*calculaGraphUser() {
      this.seriesUser = [
        ((this.myTicketsLate * 100) / this.myTickets).toFixed(1),
      ];
    },*/

    /*calculaGraphByTeam(param) {
      this.ByTeamSearch = param.status;

      if (param.value == 'all') {
        this.seriesByTeam = this.teamProc.map(team => {
          return this.ByTeamDataLate[team] + this.ByTeamDataActive[team];
        });
      }
      if (param.value == 'late') {
        this.seriesByTeam = this.teamProc.map(team => {
          return this.ByTeamDataLate[team];
        });
      }
      if (param.value == 'active') {
        this.seriesByTeam = this.teamProc.map(team => {
          return this.ByTeamDataActive[team];
        });
      }
      if (param.value == 'ahead') {
        this.seriesByTeam = this.teamProc.map(team => {
          return this.ByTeamDataAhead[team];
        });
      }
    },

    calculaGraphReproves(param) {
      this.ReprovesSearch = param.status;

      if (param.value == 'dezDias') {
        this.seriesReproves = this.teamProc.map(team => {
          return this.ReprovedByTeam10[team];
        });
      }
      if (param.value == 'trintaDias') {
        this.seriesReproves = this.teamProc.map(team => {
          return this.ReprovedByTeam30[team];
        });
      }
      if (param.value == 'sessentaDias') {
        this.seriesReproves = this.teamProc.map(team => {
          return this.ReprovedByTeam60[team];
        });
      }
    },

    calculaGraphReproveds(param) {
      this.ReprovedsSearch = param.status;

      if (param.value == 'dezDias') {
        this.seriesReproveds = this.teamProc.map(team => {
          return this.ReprovedsByTeam10[team];
        });
      }
      if (param.value == 'trintaDias') {
        this.seriesReproveds = this.teamNames.map(team => {
          return this.ReprovedsByTeam30[team];
        });
      }
      if (param.value == 'sessentaDias') {
        this.seriesReproveds = this.teamNames.map(team => {
          return this.ReprovedsByTeam60[team];
        });
      }
    },*/
  },
}
</script>

<style scoped>
</style>


