<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>Cadastro de leads</h3>
        <div class="dropdown-divider"></div>
        <h5>Aqui vamos cadastrar e editar os leads</h5>
        <table class="table table-sm table-hover table-striped tableCrmLead">
          <thead>
            <tr>
              <th data-column-id="_id" data-visible="false">ID</th>
              <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="36px"></th>
              <th data-column-id="name" data-sortable="true" data-order="asc">Nome</th>
              <th data-column-id="trade_name" data-sortable="true" data-order="asc">Razão Social</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {initGrid} from 'hennig-common'

export default {
  name: 'CrmLead',
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const addLabel = 'Novo'
      const collectionObj = 'CrmLead'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        rowClick: true,
        addLabel
      })
    }
  }
}
</script>

<style scoped>

</style>
