<template>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-12 justify-content-center pt-2">
                <apexchart 
                    type="line"
                    :options="chartOptions" 
                    :series="series"
                    height="350"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { H } from "hennig-common";

export default {
    name: 'MixChart',
    components: {VueApexCharts},
    props: {

    },
    data () {
        return {
            ServicesTotalByTeam: [],
            ServicesTotal: [],

            series: [],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                },
                    stroke: {
                    width: [0, 4]
                },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: [],
                    tooltip: {
                    enabled: true, // habilita o tooltip
                },
            },
        }
    },
    watch: {

    },
    mounted () {
        H.rpc("Dashboard", "ServicesByTeam", [], (r) => {
            if (r) {
                let servName = r.ServicesTotal.map((item) => {return item.name});
                let servQuant = r.ServicesTotal.map((item) => {return item.quant});

                let servQuantBowser = r.ServicesTotalByTeam['Equipe Bowser']?.map((item) => {return item.quantidade});
                let servQuantYoshi = r.ServicesTotalByTeam['Equipe Yoshi']?.map((item) => {return item.quantidade});
                let servQuantLuigi = r.ServicesTotalByTeam['Equipe Luigi']?.map((item) => {return item.quantidade});
                let servQuantGoomba = r.ServicesTotalByTeam['Equipe Goomba']?.map((item) => {return item.quantidade});
                let servQuantMario = r.ServicesTotalByTeam['Equipe Mário']?.map((item) => {return item.quantidade});
                let servQuantToad = r.ServicesTotalByTeam['Equipe Toad']?.map((item) => {return item.quantidade});
                
                this.series = [
                    {
                        name: 'Total',
                        type: 'column',
                        data: servQuant
                    }, 
                    {
                        name: 'Bowser',
                        type: 'line',
                        data: servQuantBowser
                    },
                    {
                        name: 'Yoshi',
                        type: 'line',
                        data: servQuantYoshi
                    },
                    {
                        name: 'Luigi',
                        type: 'line',
                        data: servQuantLuigi
                    },
                    {
                        name: 'Goomba',
                        type: 'line',
                        data: servQuantGoomba
                    },
                    {
                        name: 'Mario',
                        type: 'line',
                        data: servQuantMario
                    },
                    {
                        name: 'Toad',
                        type: 'line',
                        data: servQuantToad
                    },
                    
                ];

                this.series.forEach((item) => {
                    item.data = item.data ? item.data : [0];
                });

                this.chartOptions = {
                    labels: servName,
                };
            };
        });
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
