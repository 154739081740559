<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h3>Cadastro de clientes</h3>
                <div class="dropdown-divider"></div>
                <h5>Aqui vamos cadastrar e editar toda a base de clientes da empresa, centralizando todas as informações</h5>
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th data-column-id="_id" data-visible="false">ID</th>
                        <th data-align="center" data-column-id="e" data-formatter="e" data-sortable="false" data-width="45px"></th>
                        <!--                        <th data-align="center" data-column-id="x" data-formatter="x" data-sortable="false" data-width="36px"></th>-->
                        <!--                        <th data-align="center" data-column-id="active" data-formatter="checkbox" data-width="36px"></th>-->
                        <template v-if="canAccess('menu_relatorios')">
                            <th data-align="center" data-column-id="WorkedHours" data-formatter="WorkedHours" data-width="45px"></th>
                            <th data-align="center" data-column-id="ContractsReport" data-formatter="ContractsReport" data-width="45px"></th>
                        </template>

                        <th data-column-id="trade_name" data-order="asc" data-sortable="true">Nome Fantasia</th>
                        <th data-column-id="name" data-sortable="true">Razão Social</th>
                        <th data-column-id="tel">Telefone</th>
                        <th data-column-id="doc">CNPJ</th>
                        <th data-column-id="contract_type.name">Contrato</th>
                        <th data-column-id="contract_active" data-formatter="checkbox">Contrato Ativo</th>
                        <th data-column-id="contract_end_date" data-formatter="dt">Contrato Encerramento</th>
                        <th data-align="center" data-column-id="contract_time_fmt" data-width="100px">Horas</th>
                        <th data-align="center" data-column-id="tickets_count" data-width="100px">Tickets</th>
                        <th data-align="center" data-column-id="balance" data-width="100px">Balance</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {H, initGrid} from 'hennig-common'

export default {
    name: 'Customers',
    mounted () {
        this.init()
    },
    methods: {
        init () {
            const that = this
            const addLabel = 'Novo cliente'
            const collectionObj = 'Customer'
            initGrid({
                container: $(this.$el).find('table'),
                collectionObj,
                addLabel,
                activeFieldName: 'contract_active',
                rowClick: true,
                formatters: {
                    WorkedHours (column, row) {
                        const href = that.$router.resolve({name: 'WorkedHours', params: {customer_id: row._id}}).href
                        return `<a href=${href} title="Relatorio de horas" class="command prevent-row-click"><i class="la la-file-alt"></i></a>`
                    },
                    ContractsReport (column, row) {
                        const href = that.$router.resolve({name: 'ContractsReport', params: {customer_id: row._id}}).href
                        return `<a href=${href} title="Relatorio de horas gerencial" class="command prevent-row-click"><i class="la la-file-alt"></i></a>`
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
